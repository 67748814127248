import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-document-interface',
    styleUrls: ['./document-interface.component.scss'],
    templateUrl: './document-interface.component.pug'
})
export class DocumentInterfaceComponent implements OnInit {
    @Input() public item;
    @Output() public scrollTo: EventEmitter<number> = new EventEmitter<number>();

    public selectedFunc: number = 0;
    public func: any[] = [];

    constructor() {}

    public ngOnInit(): void {}

    public goToProperty(index: number): void {
        const func = document.getElementById('function_' + index);
        this.scrollTo.emit(func.offsetTop);
    }

    public isAsync(value: string): boolean {
        return value ? value.toLowerCase().includes('promise') : false;
    }

    public get namespace() {
        return this.item.namespace ? this.item.namespace.join('\\') : '';
    }
}
