import { Component, OnDestroy, OnInit, ViewChild, HostListener, Output, EventEmitter } from '@angular/core';
import * as Fuse from 'fuse.js';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Docs, DocMap, Doc } from 'app/services/docs';
import * as _ from 'lodash';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
    selector: 'app-navbar',
    styleUrls: ['./navbar.component.scss'],
    templateUrl: './navbar.component.pug'
})
export class NavbarComponent implements OnDestroy, OnInit {
    public faBars: IconDefinition = faBars;
    public formControl: FormControl;
    public versionControl: FormControl;
    private _formSubscription: Subscription;
    public versions: any[] = [];
    private _versionLoaded: string = null;
    private _versionSubscription: Subscription;
    private _fuse: Fuse<DocMap>;
    public results: { item: DocMap; score: number; matches: any[] }[];
    public isSearching: boolean = false;
    public hasSearched: boolean = false;
    public isLoading: boolean = false;
    public closeSearchTimeout: any;
    public closeSearchTime: number = 1000;
    public showApi: boolean = true;
    public itemToShow = null;
    public namespaceToShow = null;

    @ViewChild('rightSide')
    public rightSide: PerfectScrollbarComponent;
    @Output() public item: EventEmitter<any> = new EventEmitter<any>();
    @Output() public changeItem: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('search') public search;
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const ctrl = event.ctrlKey || false;
        if (ctrl) {
            if (event.key === 'f') {
                event.preventDefault();
                this.toggleSearch();
            }
        } else if (this.isSearching && event.key === 'Escape') {
            this.toggleSearch();
        }
    }

    constructor(private _builder: FormBuilder, private _docs: Docs) {}

    public ngOnInit() {
        this.versions = _.map(this._docs.versions, o => {
            return {
                group: 'Version ' + o.split('.')[0],
                label: 'v' + o,
                value: o
            };
        });
        this.formControl = this._builder.control(null);
        this.versionControl = this._builder.control(_.find(this.versions, ['value', this._docs.version]));
        this._formSubscription = this.formControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(data => {
                if (data && data.length >= 3 && data.length < 32) {
                    this._updateSearchResults();
                } else {
                    this.results = null;
                }
            });
        this._versionSubscription = this.versionControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(data => {
                if (data) {
                    this._docs.version = data.value;
                }
            });
    }

    public ngOnDestroy() {
        this._formSubscription.unsubscribe();
        this._versionSubscription.unsubscribe();
    }

    private _updateSearchResults() {
        if (this._versionLoaded !== this._docs.version) {
            if (!this._fuse) {
                this._fuse = new Fuse(this._docs.map, {
                    distance: 0,
                    includeMatches: true,
                    includeScore: true,
                    keys: [
                        { name: 'name', weight: 1 },
                        { name: 'comment', weight: 0.3 },
                        { name: 'methods.name', weight: 0.3 },
                        { name: 'methods.comment', weight: 0.3 },
                        { name: 'properties.name', weight: 0.3 },
                        { name: 'properties.comment', weight: 0.3 }
                    ],
                    location: 0,
                    maxPatternLength: 32,
                    minMatchCharLength: 3,
                    shouldSort: true,
                    threshold: 0.5
                });
            } else {
                this._fuse.setCollection(this._docs.map);
            }
            this._versionLoaded = this._docs.version;
        }

        const term = this.formControl.value;
        this.results = _.slice(this._fuse.search(term), 0, 20) as any;
    }

    public submit($event) {
        $event.preventDefault();
    }

    public toggleSearch() {
        if (this.isSearching === false) {
            this.formControl.reset();
            setTimeout(() => {
                this.search.nativeElement.focus();
                this._setupCloseSearchTimeout();
            }, 150);
        } else {
            this.hasSearched = false;
            this.results = [];
            this.formControl.reset();
            clearTimeout(this.closeSearchTimeout);
        }
        this.isSearching = !this.isSearching;
    }

    private _setupCloseSearchTimeout() {
        if (this.closeSearchTimeout) {
            clearTimeout(this.closeSearchTimeout);
            this.closeSearchTimeout = null;
        }
        this.closeSearchTimeout = setTimeout(() => {
            this.toggleSearch();
        }, this.closeSearchTime * 1000);
    }

    public resultMethods(result) {
        if (!result.methods) {
            result.methods = [];
            const item = result.item as DocMap;
            const matches: any[] = _.filter(result.matches, o => {
                return o.key.includes('methods');
            });
            for (let i = 0; i < matches.length; i++) {
                result.methods.push(item.methods[matches[i].arrayIndex]);
            }
        }
        return result.methods;
    }

    public resultProperties(result) {
        if (!result.properties) {
            result.properties = [];
            const item = result.item as DocMap;
            const matches: any[] = _.filter(result.matches, o => {
                return o.key.includes('properties');
            });
            for (let i = 0; i < matches.length; i++) {
                result.properties.push(item.properties[matches[i].arrayIndex]);
            }
        }
        return result.properties;
    }

    public goToItem(item, propOrMethod = null) {
        this.changeItem.emit({ path: item.path, name: item.name, propOrMethod: propOrMethod });
        this.toggleSearch();
    }
    public showItem(item) {
        if (this.itemToShow) {
            this.itemToShow.open = false;
        }
        this.itemToShow = item;
        this.itemToShow.open = true;
        if (this.rightSide) {
            setTimeout(() => {
                this.rightSide.directiveRef.scrollToY(0, 300);
            }, 50);
        }
        const sideMenu = document.getElementById('offcanvas-slide');
        if (sideMenu) {
            sideMenu.setAttribute('class', sideMenu.getAttribute('class').replace('uk-open', ''));
        }
    }
    public clearAll($event) {
        $event.preventDefault();
        this.itemToShow = null;
        this.namespaceToShow = null;
        this.closeChildren(this.data);
    }
    public closeChildren(children) {
        for (let i = 0, n = children.length; i < n; i++) {
            children[i].open = false;
            if (children[i].children) {
                this.closeChildren(children[i].children);
            }
        }
    }
    public get data(): Doc[] {
        return this._docs.documentation;
    }
    public toggleOpen(item) {
        if (item.open) {
            if (item.children) {
                this.closeChildren(item.children);
            }
            item.open = false;
        } else {
            for (let i = 0, n = this.data.length; i < n; i++) {
                this.closeChildren(this.data[i]);
                this.data[i].open = false;
            }
            item.open = true;
            if (item.namespace) {
                let target: any = this.data;
                for (let i = 0, n = item.namespace.length; i < n; i++) {
                    let found = _.find(target, ['category', item.namespace[i]]);
                    if (!found) {
                        found = _.find(target, ['name', item.namespace[i]]);
                    }
                    if (found) {
                        found.open = true;
                        target = found.children;
                    }
                }
            }
        }
    }
}
