import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './components/app/app.component';
import { HomeComponent } from './components/home/home.component';
import { DocumentClassComponent } from './components/document-class/document-class.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavbarComponent } from './components/navbar/navbar.component';

import { ProgressModule } from './modules/progress/progress.module';
import { routes } from './routes';
import { Api } from './services/api';
import { Nl2BrPipe } from './pipes/nl2br.pipe';
import { Tab2SpacePipe } from './pipes/tab2spaces.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { CodeFormatPipe } from './pipes/code-format.pipe';
import { Docs } from './services/docs';
import { DocumentFunctionComponent } from './components/document-function/document-function.component';
import { DocumentInterfaceComponent } from './components/document-interface/document-interface.component';
import { DocumentEnumComponent } from './components/document-enum/document-enum.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false
};

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        HomeComponent,
        DocumentClassComponent,
        DocumentFunctionComponent,
        DocumentInterfaceComponent,
        DocumentEnumComponent,
        FooterComponent,
        NavbarComponent,
        MenuComponent,
        Nl2BrPipe,
        Tab2SpacePipe,
        SafePipe,
        CodeFormatPipe
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        SimpleNotificationsModule.forRoot(),
        routes,
        ReactiveFormsModule,
        NgSelectModule,
        ProgressModule,
        FontAwesomeModule,
        PerfectScrollbarModule
    ],
    providers: [
        Api,
        Docs,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class AppModule {}
