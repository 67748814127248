import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Api } from './api';
const versions = require('./../../../public/docs/versions.json');

export interface DocMap {
    id: number;
    path: string;
    name: string;
    methods: { name: string; comment: string }[];
    properties: { name: string; comment: string }[];
    type: string;
    comment: string;
    indices?: any[];
    score?: number;
}

export interface Doc {
    category?: string;
    children?: Doc[];
    open?: boolean;

    comment?: string;
    example?: string;
    methods?: MethodDoc[];
    name?: string;
    type?: string;
    properties?: PropertyDoc[];
}

export interface MethodDoc {
    name?: string;
    comment?: string;
    throws?: string[];
    signatures?: SignatureDoc[];
}

export interface ReturnDoc {
    comment?: string;
    type?: string;
}

export interface SignatureDoc {
    comment?: string;
    example?: string;
    returns?: ReturnDoc;
    params?: ParamDoc[];
}

export interface ParamDoc {
    name?: string;
    optional?: boolean;
    comment?: string;
    type?: string;
}

export interface PropertyDoc {
    name?: string;
    example?: string;
    comment?: string;
    type?: string;
    getter?: boolean;
}

// Our API Service which will handle all comms wth the backend server
@Injectable()
export class Docs {
    private _version: string = '';
    private _versions: string[];
    private _docs: { map: DocMap[]; documentation: Doc[] } = null;
    private _loading: boolean = false;

    constructor(private _api: Api) {
        this._getVersions();
    }

    private _getVersions() {
        this._versions = _.sortBy(versions, o => {
            return +o * -1;
        });
        if (!this._version) {
            this._version = this._versions[0];
            this._fetchDocs();
        }
    }

    get documentation(): any {
        return this._docs ? this._docs.documentation : null;
    }

    get map(): DocMap[] {
        return this._docs ? this._docs.map : null;
    }

    get version(): string {
        return this._version;
    }

    set version(version: string) {
        if (version !== this._version) {
            this._version = version;
            this._fetchDocs();
        }
    }

    get versions(): string[] {
        return this._versions;
    }

    private _fetchDocs() {
        this._docs = null;
        this._loading = true;
        this._api.get(`/docs/${this.version.replace(/\./gi, '_')}/documentation.json`).subscribe(
            data => {
                this._loading = false;
                if (!data.error) {
                    setTimeout(() => {
                        this._docs = data;
                    });
                }
            },
            err => {
                this._loading = false;
            }
        );
    }

    get isLoading(): boolean {
        return this._loading;
    }
}
