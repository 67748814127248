import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-document-enum',
    styleUrls: ['./document-enum.component.scss'],
    templateUrl: './document-enum.component.pug'
})
export class DocumentEnumComponent implements OnInit {
    @Input() public item;

    public selectedFunc: number = 0;
    public func: any[] = [];

    constructor() {}

    public ngOnInit(): void {}

    public goToProperty(index: number): void {
        const func = document.getElementById('function_' + index);
        window.scrollTo({
            behavior: 'smooth',
            top: func.offsetTop + 100
        });
    }

    public isAsync(value: string): boolean {
        return value.toLowerCase().includes('promise');
    }

    public get namespace() {
        return this.item.namespace ? this.item.namespace.join('\\') : '';
    }
}
