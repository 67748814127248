import { ModuleWithProviders, Component, HostBinding, OnDestroy, Input, ElementRef, NgZone, Renderer, Directive } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

/** A single degree in radians. */
const DEGREE_IN_RADIANS = Math.PI / 180;
/** Duration of the indeterminate animation. */
const DURATION_INDETERMINATE = 650;
/** Start animation value of the indeterminate animation */
const startIndeterminate = 5;
/** End animation value of the indeterminate animation */
const endIndeterminate = 95;
/* Maximum angle for the arc. The angle can't be exactly 360, because the arc becomes hidden. */
const MAX_ANGLE = 359.99 / 100;

type EasingFn = (currentTime: number, startValue: number, changeInValue: number, duration: number) => number;

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.pug',
    styleUrls: ['./progress.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: '1' })),
            transition('* => void', [style({ opacity: 1 }), animate(250, style({ opacity: 0 }))]),
            transition('void => *', [style({ opacity: 0 }), animate(250, style({ opacity: 1 }))])
        ])
    ],
    host: { '[@fadeInOut]': '' }
})
export class ProgressComponent implements OnDestroy {
    /** The id of the last requested animation. */
    private _lastAnimationId: number = 0;

    /** The id of the indeterminate interval. */
    private _interdeterminateInterval: number;

    /** The SVG <path> node that is used to draw the circle. */
    private _path: SVGPathElement;
    public path: string;

    /** @docs-private */
    get interdeterminateInterval() {
        return this._interdeterminateInterval;
    }
    /** @docs-private */
    set interdeterminateInterval(interval: number) {
        clearInterval(this._interdeterminateInterval);
        this._interdeterminateInterval = interval;
    }

    /** Clean up any animations that were running. */
    ngOnDestroy() {
        this.interdeterminateInterval = null;
    }

    constructor(private _ngZone: NgZone, private _elementRef: ElementRef, private _renderer: Renderer) {
        this._startIndeterminateAnimation();
    }

    /** Animates the arc path */
    private _animateCircle(animateFrom: number, animateTo: number, ease: EasingFn, duration: number, rotation: number) {
        let id = ++this._lastAnimationId;
        let startTime = Date.now();
        let changeInValue = animateTo - animateFrom;

        let animation = () => {
            let elapsedTime = Math.max(0, Math.min(Date.now() - startTime, duration));

            this._renderArc(ease(elapsedTime, animateFrom, changeInValue, duration), rotation);

            // Prevent overlapping animations by checking if a new animation has been called for and
            // if the animation has lasted longer than the animation duration.
            if (id === this._lastAnimationId && elapsedTime < duration) {
                requestAnimationFrame(animation);
            }
        };

        // Run the animation outside of Angular's zone, in order to avoid
        // hitting ZoneJS and change detection on each frame.
        this._ngZone.runOutsideAngular(animation);
    }

    /**
     * Starts the indeterminate animation interval, if it is not already running.
     */
    private _startIndeterminateAnimation() {
        let rotationStartPoint = 0;
        let start = startIndeterminate;
        let end = endIndeterminate;
        let duration = DURATION_INDETERMINATE;
        let animate = () => {
            this._animateCircle(start, end, materialEase, duration, rotationStartPoint);
            // Prevent rotation from reaching Number.MAX_SAFE_INTEGER.
            rotationStartPoint = (rotationStartPoint + end) % 100;
            let temp = start;
            start = -end;
            end = -temp;
        };

        if (!this.interdeterminateInterval) {
            this._ngZone.runOutsideAngular(() => {
                this.interdeterminateInterval = window.setInterval(animate, duration + 50, 0, false);
                animate();
            });
        }
    }

    /**
     * Renders the arc onto the SVG element. Proxies `getArc` while setting the proper
     * DOM attribute on the `<path>`.
     */
    private _renderArc(currentValue: number, rotation: number) {
        // Caches the path reference so it doesn't have to be looked up every time.
        let path = (this._path = this._path || this._elementRef.nativeElement.querySelector('path'));

        // Ensure that the path was found. This may not be the case if the
        // animation function fires too early.
        if (path) {
            path.setAttribute('d', getSvgArc(currentValue, rotation));
        }
    }
}

/** Converts Polar coordinates to Cartesian. */
function polarToCartesian(radius: number, pathRadius: number, angleInDegrees: number) {
    let angleInRadians = (angleInDegrees - 90) * DEGREE_IN_RADIANS;

    return radius + pathRadius * Math.cos(angleInRadians) + ',' + (radius + pathRadius * Math.sin(angleInRadians));
}

/**
 * Easing function for linear animation.
 */
function linearEase(currentTime: number, startValue: number, changeInValue: number, duration: number) {
    return (changeInValue * currentTime) / duration + startValue;
}

/**
 * Easing function to match material design indeterminate animation.
 */
function materialEase(currentTime: number, startValue: number, changeInValue: number, duration: number) {
    let time = currentTime / duration;
    let timeCubed = Math.pow(time, 3);
    let timeQuad = Math.pow(time, 4);
    let timeQuint = Math.pow(time, 5);
    return startValue + changeInValue * (6 * timeQuint + -15 * timeQuad + 10 * timeCubed);
}

function getSvgArc(currentValue: number, rotation: number) {
    let startPoint = rotation || 0;
    let radius = 50;
    let pathRadius = 40;

    let startAngle = startPoint * MAX_ANGLE;
    let endAngle = currentValue * MAX_ANGLE;
    let start = polarToCartesian(radius, pathRadius, startAngle);
    let end = polarToCartesian(radius, pathRadius, endAngle + startAngle);
    let arcSweep = endAngle < 0 ? 0 : 1;
    let largeArcFlag: number;

    if (endAngle < 0) {
        largeArcFlag = endAngle >= -180 ? 0 : 1;
    } else {
        largeArcFlag = endAngle <= 180 ? 0 : 1;
    }

    return `M${start}A${pathRadius},${pathRadius} 0 ${largeArcFlag},${arcSweep} ${end}`;
}
