import { Component, ViewChild } from '@angular/core';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { Doc, Docs } from 'app/services/docs';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import * as _ from 'lodash';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

// loads the Icon plugin
UIkit.use(Icons);

@Component({
    selector: 'app-homepage',
    styleUrls: ['./home.component.scss'],
    templateUrl: './home.component.pug'
})
export class HomeComponent {
    public faBars: IconDefinition = faBars;
    public showApi: boolean = true;
    public itemToShow = null;
    public namespaceToShow = null;
    @ViewChild('rightSide')
    public rightSide: PerfectScrollbarComponent;

    constructor(private _docs: Docs) {}

    public showItem(item) {
        if (this.itemToShow) {
            this.itemToShow.open = false;
        }
        this.itemToShow = item;
        this.itemToShow.open = true;
        if (this.rightSide) {
            setTimeout(() => {
                this.rightSide.directiveRef.scrollToY(0, 300);
            }, 50);
        }
        const sideMenu = document.getElementById('offcanvas-slide');
        if (sideMenu) {
            sideMenu.setAttribute('class', sideMenu.getAttribute('class').replace('uk-open', ''));
        }
    }

    public clearAll($event) {
        this.itemToShow = null;
        this.namespaceToShow = null;
        this.closeChildren(this.data);
    }

    public goToItem($event: { path: string; name: string; propOrMethod?: any }) {
        const split = $event.path.split('/');
        let target: any = this.data;
        for (let i = 0, n = split.length; i < n; i++) {
            if (i < n - 1) {
                target = _.find(target, ['category', split[i]]);
                if (target && target.children) {
                    target.open = true;
                    target = target.children;
                }
            } else if (i === n - 1) {
                target.open = true;
                target = _.find(target, ['name', $event.name]);
                if ($event.propOrMethod) {
                    setTimeout(() => {
                        const method = _.findIndex(target.methods, ['name', $event.propOrMethod.name]);
                        if (method !== -1) {
                            const func = document.getElementById('function_' + method);
                            if (func) {
                                this.rightSide.directiveRef.scrollToY(func.offsetTop, 300);
                            }
                        } else {
                            const property = _.findIndex(target.properties, ['name', $event.propOrMethod.name]);
                            if (property !== -1) {
                                const prop = document.getElementById('function_' + method);
                                if (prop) {
                                    this.rightSide.directiveRef.scrollToY(prop.offsetTop, 300);
                                }
                            }
                        }
                    }, 250);
                }
            }
        }
        if (target) {
            this.showApi = true;
            this.showItem(target);
            target.open = true;
        }
    }

    public toggleOpen(item) {
        if (item.open) {
            if (item.children) {
                this.closeChildren(item.children);
            }
            item.open = false;
        } else {
            for (let i = 0, n = this.data.length; i < n; i++) {
                this.closeChildren(this.data[i]);
                this.data[i].open = false;
            }
            item.open = true;
            if (item.namespace) {
                let target: any = this.data;
                for (let i = 0, n = item.namespace.length; i < n; i++) {
                    let found = _.find(target, ['category', item.namespace[i]]);
                    if (!found) {
                        found = _.find(target, ['name', item.namespace[i]]);
                    }
                    if (found) {
                        found.open = true;
                        target = found.children;
                    }
                }
            }
        }
    }

    public closeChildren(children) {
        for (let i = 0, n = children.length; i < n; i++) {
            children[i].open = false;
            if (children[i].children) {
                this.closeChildren(children[i].children);
            }
        }
    }

    public get isDocsLoading(): boolean {
        return this._docs.isLoading;
    }

    public get data(): Doc[] {
        return this._docs.documentation;
    }
}
