import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'code'
})
export class CodeFormatPipe implements PipeTransform {
    transform(value: string): any {
        let result;
        if (value) {
            result = value
                .replace(/try\{/g, '<span class="declaration">try</span>{')
                .replace(/catch\(/g, '<span class="declaration">catch</span>(')
                .replace(/this./g, '<span class="type">this</span>.')
                .replace(/(\.{3})/g, '<span class="comment">...</span><br/>')
                .replace(/\.(\w+)\(/g, '.<span class="function">$1</span>(')
                .replace(/\{(?!\s?})/g, '<span class="bracket ml-2">{</span><br/><div class="ml-5">')
                .replace(/\{(?=\s?})/g, '<span class="bracket ml-2">{</span>')
                .replace(/\\\@(\w+)\(/g, '<span class="function">@$1</span>(')
                .replace(/\}(?!\)+)/g, '</div><span class="bracket mr-2">}</span><br/>')
                .replace(/\}\)/g, '</div><span class="bracket mr-2">})</span><br/>')
                .replace(/\(/g, '<span class="bracket ml-1">(</span>')
                .replace(/\)/g, '<span class="bracket mr-1">)</span>')
                .replace(/\,/g, '<span class="bracket ml-1 mr-2">,</span>')
                .replace(/\./g, '<span class="bracket">.</span>')
                .replace(/\=\>/g, '<span class="returns">=></span>')
                .replace(/as\s(\w+)/g, '<span class="returns">as </span><span class="type">$1</span>')
                .replace(/\sget\s/g, ' <span class="returns mx-1">get</span> ')
                .replace(/\sset\s/g, ' <span class="returns mx-1">set</span> ')
                .replace(/import/g, '<span class="declaration">import</span>')
                .replace(/from\s\'((@?\w+\/\w+)+)\'/g, '<span class="declaration">from </span><span class="type">\'$1\'</span>')
                .replace(/constructor/g, '<span class="declaration">constructor</span>')
                .replace(/private\s/g, '<span class="declaration">private </span>')
                .replace(/public\s/g, '<span class="declaration">public </span>')
                .replace(/return\s/g, '<span class="returns">return </span>')
                .replace(/const\s/g, '<span class="declaration">const </span>')
                .replace(/:\s?([A-Z]\w+)/gi, '<span class="bracket">: </span><span class="type">$1</span>')
                .replace(/\sawait\s/g, '<span class="declaration"> await </span>')
                .replace(/\s=\s/g, '<span class="bracket"> = </span>')
                .replace(/\s\+\s/g, '<span class="bracket"> + </span>')
                .replace(/\s\-\s/g, '<span class="bracket"> - </span>')
                .replace(/\/\/([\s?\w+]{1,})/g, '<span class="comment">// $1</span>')
                .replace(/;/g, '<span class="bracket">;</span><br/>')
                .replace(/(\'(\w\s?)+\')/g, '<span class="type">$1</span>');
        }
        return result ? result : value;
    }
}
