import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { style, state, animate, transition, trigger } from '@angular/animations';

@Component({
    animations: [
        trigger('fadeInOut', [
            state('false', style({ opacity: 0 })),
            transition('false => true', [
                animate(300, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
            ]),
            transition('true => false', [
                animate(300, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None, // Disabled for global styles
    selector: 'app-peco-ts-docs',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.pug'
})
export class AppComponent implements OnInit {
    public toastOptions: any = {
        position: ['top', 'right'],
        timeOut: 2500
    };

    constructor() {}

    public ngOnInit(): void {}
}
