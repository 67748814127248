import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nl2br'
})
export class Nl2BrPipe implements PipeTransform {
    transform(value: string): any {
        let result;
        if (value) {
            result = value.replace(/(?:\r\n|\r|\n)/g, '<br/>');
        }
        return result ? result : value;
    }
}
