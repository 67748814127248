import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Our API Service which will handle all comms with the backend server
@Injectable()
export class Api {
    private _endPoint: string = process.env.API;
    private jwt: string = '';

    private _options: {
        headers: HttpHeaders;
        withCredentials: boolean;
    } = {
        headers: null,
        withCredentials: true
    };

    constructor(private _http: HttpClient, private _router: Router) {}

    public get(url: string, options?: Object): Observable<any> {
        // Our wrapper that injects the options into HTTP service
        let httpOptions = _.clone(this._options);
        if (options) {
            httpOptions = this._mergeObjects(httpOptions, options);
        }
        if (!httpOptions.headers) {
            httpOptions.headers = new HttpHeaders().set('X-ANGULAR', '1');
        } else {
            httpOptions.headers = httpOptions.headers.set('X-ANGULAR', '1');
        }
        return this._http.get(this._endPoint + url, httpOptions).pipe(catchError(this._handleError()));
    }

    public post(url: string, data: Object, options?: any): Observable<any> {
        // Our wrapper that injects the options into HTTP service
        let httpOptions = _.clone(this._options);
        if (options) {
            httpOptions = this._mergeObjects(httpOptions, options);
        }
        if (!httpOptions.headers) {
            httpOptions.headers = new HttpHeaders().set('X-ANGULAR', '1');
        } else {
            httpOptions.headers = httpOptions.headers.set('X-ANGULAR', '1');
        }
        return this._http.post(this._endPoint + url, data, httpOptions).pipe(catchError(this._handleError()));
    }

    private _handleError(): any {
        return (error: HttpErrorResponse | any) => {
            // Handles the error and throws an error observable
            if (error.status === 403) {
                this._router.navigate(['/403']);
            }
            return of({ error: true } as any);
        };
    }

    private _mergeObjects(obj1: any, obj2: Object): any {
        for (const k in obj2) {
            if (obj1[k]) {
                _.extend(obj1[k], obj2[k]);
            } else {
                obj1[k] = obj2[k];
            }
        }
        return obj1;
    }

    public setJwt(jwt) {
        this.jwt = jwt;
        if (!this._options.headers) {
            this._options.headers = new HttpHeaders().set('X-JWT', this.jwt);
        } else {
            this._options.headers = this._options.headers.set('X-JWT', this.jwt);
        }
    }

    public clearJwt() {
        this.jwt = '';
        if (this._options.headers) {
            this._options.headers = this._options.headers.delete('X-JWT');
        }
    }
}
