import { NgModule } from '@angular/core';
import { ProgressComponent} from './progress.component';

@NgModule({
  declarations: [
    ProgressComponent,
  ],
  exports: [
    ProgressComponent,
  ],
})
export class ProgressModule { }